.notifier-add {
    position: absolute;
    top: 0px;
    right: 3px;
}

.notifier button {
    width: 160px;
    padding: 7px 30px;
    margin: 10px 5px;
    font-weight: bold;
    background-color: #005498;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.modal {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    text-align: left;
}

.modal_content {
    background-color: white;
    position: absolute;
    top: 21%;
    left: 31%;
    width: 40%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #005498;
    height: 300px;
    overflow: auto;
}

.close {
    color: #005498;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    right: 9px;
    top: 0px;
}

.close:hover {
    cursor: pointer;
}

.modal_content div {
    margin: 10px 20px 10px 10px;
}

.modal_content input,
.modal_content select {
    padding: 5px 7px;
    margin: 0px 5px;
    border: 2px solid #005498;
    border-radius: 5px;
}

.modal_content input[name="value"] {
    width: calc(100% - 18px);
}

.modal_content select[name="condition"] {
    width: 100%;
}

.modal_content select[name="coin"] {
    width: 100%;
    text-transform: uppercase;
}

.modal_content button {
    padding: 7px 30px;
    margin: 0px 5px;
    font-weight: bold;
    background-color: #005498;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    width: 100%
}


.viewNotifier-table {
    border-radius: 5px;
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.viewNotifier-table thead tr {
    background-color: #005498;
    color: #ffffff;
    text-align: left;
}

.viewNotifier-table th {
    background-color: #005498;
    position: sticky;
    top: 0;
}

.viewNotifier-table th,
.viewNotifier-table td {
    padding: 12px 15px;
    text-transform: uppercase;
}

.viewNotifier-table tbody tr {
    border-bottom: thin solid #dddddd;
}

.viewNotifier-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.viewNotifier-table tbody tr:last-of-type {
    border-bottom: 2px solid #005498;
}

.viewNotifier-table button {
    padding: 0px;
    margin: 0px;
}