.dataView {
    width: 100%;
    height: 400px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin-top: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;


}

.dataView-params {
    border: 1px solid #005498;
    padding: 5px;
    margin: 5px 5px 5px 10px;
    text-align: end;
    margin-top: 5px;
    border-radius: 5px;
}

.dataView-params .tableName {
    position: absolute;
    padding: 5px 7px;
    margin: 2px 5px;
    border-radius: 5px;
}

.dataView-params input {
    padding: 5px 7px;
    margin: 0px 5px;
    border: 2px solid #005498;
    border-radius: 5px;
}

.dataView-params input[name="sheetname"] {
    width: 100px;
}

.dataView-params input[name="cell1"] {
    width: 40px;
}

.dataView-params input[name="cell2"] {
    width: 40px;
}

.dataView-params button {
    padding: 7px 30px;
    margin: 0px 5px;
    font-weight: bold;
    background-color: #005498;
    color: #ffffff;
    text-align: center;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.dataView-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: calc(100% - 10px);
    overflow: auto;
    height: calc(100% - 55px);
    margin: 10px 0px 10px 10px;
}

/*.dataView-table td,
.dataView-table th {
    border: 1px solid #ddd;
    padding: 8px;
}

.dataView-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dataView-table tr:hover {
    background-color: #ddd;
}

.dataView-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #5b4caf;
    color: white;
    position: sticky;
    top: 0;
} */

.dataView-table table {
    border-radius: 5px;
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.dataView-table table thead tr {
    background-color: #005498;
    color: #ffffff;
    text-align: left;
}

.dataView-table table th {
    background-color: #005498;
    position: sticky;
    top: 0;
}

.dataView-table table th,
.dataView-table table td {
    padding: 12px 15px;
}

.dataView-table table tbody tr {
    border-bottom: thin solid #dddddd;
}

.dataView-table table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.dataView-table table tbody tr:last-of-type {
    border-bottom: 2px solid #005498;
}